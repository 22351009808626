<template>
  <div>
    <v-dialog v-model='isDetailPo' persistent max-width='1000'>
      <v-card>
        <v-card-title>
          <span>
            {{ $t('detailPo') }} <v-chip small color='secondary'>{{ poData.purchase_id }}</v-chip>
          </span>
          <v-spacer></v-spacer>
          <v-autocomplete
            v-model='dealerSelected'
            outlined
            dense
            :label="$t('selectDealer')"
            :items='drugfromList'
            disabled
            hide-details='auto'
            style='max-width: 350px'
            return-object
            :no-data-text="$t('no_information')"
            item-value='drugfrom_id'
            item-text='drugfrom_name'
          >
          </v-autocomplete>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class='pt-1 pb-0'>
          <h4 class='font-weight-bold text-decoration-underline'>
            {{ $t('dealerInformation') }}
          </h4>
          <v-row>
            <v-col cols='12' md='6' lg='4'>
              <span class='font-weight-bold'>{{ $t('dealer') }} : </span>
              <span>{{ dealerSelected.purchase_from_name }}</span>
            </v-col>
            <v-col cols='12' md='6' lg='4'>
              <span class='font-weight-bold'>{{ $t('tax_number') }} : </span>
              <span>{{ dealerSelected.drugfrom_tax }}</span>
            </v-col>
            <v-col cols='12' md='6' lg='4'>
              <span class='font-weight-bold'>{{ $t('tel') }} : </span>
              <span>{{ dealerSelected.drugfrom_tel }}</span>
            </v-col>
          </v-row>
          <v-divider class='my-1'></v-divider>
          <v-card flat color='transparent' :disabled='!dealerSelected.drugfrom_id'>
            <div class='d-flex align-center justify-space-between my-2'>
              <h4 class='font-weight-bold text-decoration-underline'>
                {{ $t('list_drug_equipment') }}
              </h4>
            </div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th>{{ $t('code') }}</th>
                  <th style='min-width: 200px'>{{ $t('list') }}</th>
                  <th>{{ $t('tax') }}</th>
                  <th>{{ $t('amount') }}</th>
                  <th>{{ $t('cost_price') }}</th>
                  <th>{{ $t('total_price') }}</th>
                  <th>{{ $t('unit') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='(item,index) in cartList' :key='index'>
                  <td>{{ item.purchaseorder_drug_id }}</td>
                  <td style='min-width: 200px'>{{ item.purchaseorder_drug_name }}</td>
                  <td>{{ item.purchaseorder_vat }}%</td>
                  <td>{{ item.purchaseorder_amount }}</td>
                  <td>{{ item.purchaseorder_cost }}</td>
                  <td>{{ item.purchaseorder_total }}</td>
                  <td>{{ item.purchaseorder_drug_unit }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='secondary' outlined @click="$emit('update:isDetailPo',false)">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import dealer from '@/api/DrugManagement/dealer'
import po from '@/api/DrugManagement/po'
import { mdiPlus, mdiDeleteOutline, mdiPencilOutline, mdiAlertOutline } from '@mdi/js'
import { required } from '@core/utils/validation'
import store from '@/store'
import { i18n } from '@/plugins/i18n'
import { formatPrice } from '@/plugins/filters'
import document from '@/api/systemSetting/document'


export default {
  model: {
    prop: 'isDetailPo',
    event: 'update:isDetailPo',
  },
  props: {
    isDetailPo: {
      type: Boolean,
      default: false,
    },
    poData: {
      type: Object,
      default: () => ({}),
    },
  },
  filters: {
    formatPrice,
  },
  setup(props, { emit }) {
    const drugfromList = ref([])
    const drugList = ref([])
    const dealerSelected = ref({})
    const cartList = ref([])
    const isConfirmUpdatePo = ref(false)
    const formConfirmUpdatePo = ref(null)
    const isShowDate = ref(false)
    const updatePoLoading = ref(false)
    const isEditDrugInPo = ref(false)
    const dataEditDrugInPo = ref({})
    const formEditDrugInCart = ref(null)
    const editCartLoading = ref(false)
    const isRemoveDrugInPo = ref(false)
    const idRemoveDrugInPo = ref('')
    const loadingDelete = ref(false)

    const showConfirmRemoveDrugInPo = (id) => {
      idRemoveDrugInPo.value = id
      isRemoveDrugInPo.value = true
    }

    const getDrugfromList = async () => {
      const data = await dealer.dealerList()
      drugfromList.value = data
      dealerSelected.value = data.find((item) => item.dealer_id === props.poData.dealer_id)
    }

    const getDrugList = async () => {
      const body = {
        searchtext: '',
        drug_status_id: 1,
        drug_category_id: '',
        druggroup_id: '',
        drugtype_id: '',
        active_page: 1,
        per_page: '0',
      }
      const { data } = await po.poDrugList(body)
      drugList.value = data
    }

    const getCartList = async () => {
      cartList.value = props.poData.purchaseorder
    }

    const updatePo = async () => {
      const isFormValid = formConfirmUpdatePo.value.validate()
      if (!isFormValid) return
      updatePoLoading.value = true

      const res = await po.poUpdate({
        purchase_id_pri: props.poData.purchase_id_pri,
        purchase_expdate: props.poData.purchase_expdate,
        purchase_comment: props.poData.purchase_comment,
      })
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      updateStatusConfirm()
    }

    const editDrugInPoList = (item) => {
      dataEditDrugInPo.value = item
      isEditDrugInPo.value = true
    }

    const editDrugInCart = async () => {
      const isFormValid = formEditDrugInCart.value.validate()
      if (!isFormValid) return
      editCartLoading.value = true
      const res = await po.poUpdate({
        purchase_id_pri: props.poData.purchase_id_pri,
        purchaseorder_id_pri: dataEditDrugInPo.value.purchaseorder_id_pri,
        purchaseorder_vat: dataEditDrugInPo.value.purchaseorder_vat,
        purchaseorder_amount: dataEditDrugInPo.value.purchaseorder_amount,
        purchaseorder_cost: dataEditDrugInPo.value.purchaseorder_cost,
        purchaseorder_total: (dataEditDrugInPo.value.purchaseorder_amount * dataEditDrugInPo.value.purchaseorder_cost) + (dataEditDrugInPo.value.purchaseorder_amount * dataEditDrugInPo.value.purchaseorder_cost * dataEditDrugInPo.value.purchaseorder_vat / 100),
      })

      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      editCartLoading.value = false
      isEditDrugInPo.value = false
    }

    const removeDrugInPo = async () => {
      loadingDelete.value = true
      const res = await po.poRemoveDrugInCartPo(
        props.poData.purchase_id_pri,
        idRemoveDrugInPo.value,
      )
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      isRemoveDrugInPo.value = false
      cartList.value.splice(cartList.value.findIndex((item) => item.purchaseorder_id_pri === idRemoveDrugInPo.value), 1)
      loadingDelete.value = false
    }

    const updateStatusConfirm = async () => {
      const res = await po.poUpdateStatusConfirm({
        purchase_id_pri: props.poData.purchase_id_pri,
        purchase_comment: props.poData.purchase_comment,
      })
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      updatePoLoading.value = false
      emit('update:isDetailPo', false)
      emit('onUpdate', false)
    }


    watch(() => props.isDetailPo, (val) => {
      if (val) {
        dealerSelected.value = {}
        getCartList()
        getDrugList()
        getDrugfromList()
      }
    })

    return {
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAlertOutline,
      },
      isEditDrugInPo,
      editCartLoading,
      dataEditDrugInPo,
      isRemoveDrugInPo,
      showConfirmRemoveDrugInPo,
      formEditDrugInCart,
      removeDrugInPo,
      editDrugInPoList,
      cartList,
      drugfromList,
      loadingDelete,
      required,
      updatePo,
      getCartList,
      editDrugInCart,
      isConfirmUpdatePo,
      drugList,
      dealerSelected,
      isShowDate,
      formConfirmUpdatePo,
      updatePoLoading,
    }
  },

}
</script>
